<template>
  <div class="form-group form-input-calendar">
    <label :for="input.id" class="font-weight-medium">
      {{ input.label }}
    </label>
    <div class="form-control p-0 btn-group">
      <button type="button" class="btn btn-white text-left">
        {{ Helper.formatDate(model) }}
      </button>
      <button
        type="button"
        class="btn btn-light border-left dropdown-toggle-split flex-shrink-1 flex-grow-0"
        data-toggle="dropdown"
        aria-haspopup="true"
        aria-expanded="false"
      >
        <i class="fas fa-calendar-alt text-muted"></i>
      </button>
      <div class="dropdown-menu border-0" @click.stop="emptyMethod">
        <div class="dropdown-item">
          <v-date-picker
            class="inline-calendar shadow"
            v-model="model"
            trim-weeks
            mode="date"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "text-input-component",
  props: ["input"],
  data() {
    return {
      model: null,
    };
  },
  watch: {
    "input.model": {
      handler() {
        this.model = this.input.model;
      },
      deep: true,
      immediate: true,
    },
    model() {
      this.$emit("change", { id: this.input.id, model: this.model });
    },
  },
  methods: {
    emptyMethod() {
      return;
    },
  },
};
</script>