<template>
  <div class="custom-modal" :class="{ 'custom-modal-full': isFull }">
    <div class="custom-modal-content">
      <div class="custom-modal-header">
        <h3>{{ title }}</h3>
        <button class="btn btn-modal-close" @click="close()">
          <i class="fas fa-times"></i>
        </button>
      </div>
      <div class="custom-modal-body">
        <slot />
      </div>
    </div>
  </div>
</template>

<script>
import $ from "jquery";

export default {
  name: "modal-component",
  props: ["title", "isFull", "id"],
  data() {
    return {
      result: {
        show: false,
        data: null,
      },
      body: null,
    };
  },
  methods: {
    close() {
      $("body").removeClass("modal-open");

      this.$emit("close", this.result);
    },
  },
  mounted() {
    $("body").addClass("modal-open");
  },
};
</script>