<template>
  <div class="form-group form-input-file">
    <label for="header_image" class="font-weight-medium">
      {{ input.label }}
    </label>
    <div class="initial-image" v-if="model && !modelIsFile">
      <img :src="model.url" />
      <button class="btn btn-light" @click="clear()">
        <i class="far fa-trash-alt text-danger"></i>
      </button>
    </div>
    <div class="custom-file" v-else>
      <input
        type="file"
        class="custom-file-input"
        :id="input.id"
        :ref="input.id"
        :accept="input.accept"
        @change="fileChanged"
      />
      <label class="custom-file-label" :for="input.id">
        {{ model ? model[0].name : "Select file" }}
      </label>
      <button
        class="btn btn-link btn-input-close"
        @click="clear()"
        v-if="model"
      >
        clear
      </button>
    </div>
  </div>
</template>

<script>
export default {
  name: "file-input-component",
  props: ["input"],
  data() {
    return {
      model: null,
    };
  },
  watch: {
    model() {
      this.$emit("change", { id: this.input.id, model: this.model });
    },
    "input.model": {
      handler() {
        this.model = this.input.model;
      },
      immediate: true,
      deep: true,
    },
  },
  computed: {
    modelIsFile() {
      return this.model[0] instanceof File;
    },
  },
  methods: {
    fileChanged(e) {
      this.model = e.target.files;
    },
    clear() {
      this.model = null;

      const fileInput = this.$refs[this.input.id];

      if (fileInput) {
        fileInput.value = "";
      }
    },
  },
};
</script>