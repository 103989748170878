<template>
  <div class="form">
    <div class="row pb-4">
      <template v-for="input in inputs">
        <component
          :is="`${input.type}-input`"
          :key="input.id"
          :input="input"
          :options="options && options[input.id]"
          class="col-12"
          :class="`${
            typeof input.col != undefined
              ? input.col === 0
                ? 'col-sm'
                : 'col-sm-'.concat(input.col)
              : 'col-sm-12'
          }`"
          @change="inputChanged"
        />
      </template>
    </div>
    <div
      class="pt-3 border-top d-flex justify-content-between align-items-center"
    >
      <button class="btn btn-light px-4" @click="close()">Cancel</button>
      <button class="btn btn-success px-4" @click="save()">
        <i class="fas fa-save mr-2"></i>
        Save
      </button>
    </div>
  </div>
</template>

<script>
import TextInput from "@/components/inputs/Text";
import TextareaInput from "@/components/inputs/Textarea";
import SelectInput from "@/components/inputs/Select";
import FileInput from "@/components/inputs/File";
import SwitchInput from "@/components/inputs/Switch";

export default {
  name: "site-info-form-component",
  props: ["options", "site"],
  components: {
    TextInput,
    TextareaInput,
    SelectInput,
    FileInput,
    SwitchInput,
  },
  data() {
    return {
      inputs: [
        {
          type: "file",
          label: "Header Image",
          id: "header_image",
          model: null,
          accept: "image/*",
        },
        {
          type: "text",
          label: "Site ID",
          id: "site_id",
          model: "",
          col: 6,
        },
        {
          type: "select",
          label: "Structure Type",
          id: "structuretype",
          model: null,
          col: 6,
        },
        {
          type: "text",
          label: "Site Name",
          id: "name",
          model: "",
        },
        {
          type: "textarea",
          label: "Address",
          id: "address",
          model: "",
        },
        {
          type: "text",
          label: "City",
          id: "city",
          model: "",
          col: 6,
        },
        {
          type: "select",
          label: "State",
          id: "state",
          model: null,
          col: 6,
        },
        {
          type: "text",
          label: "Latitude",
          id: "lat",
          model: null,
          col: 6,
        },
        {
          type: "text",
          label: "Longitude",
          id: "lng",
          model: null,
          col: 6,
        },
        {
          type: "text",
          label: "PM-Ship",
          id: "pm_ship",
          model: null,
          col: 6,
        },
        {
          type: "switch",
          label: "Genset",
          id: "genset",
          model: false,
          col: 6,
        },
        {
          type: "text",
          label: "TNB Meter No.",
          id: "tnb_meter_no",
          model: null,
          col: 6,
        },
        {
          type: "text",
          label: "TNB Account No.",
          id: "tnb_account_no",
          model: null,
          col: 6,
        },
      ],
    };
  },
  methods: {
    close() {
      this.$parent.close();
    },
    async save() {
      this.$parent.$emit("toggleSpinner", true);

      let body = {};

      this.inputs
        .filter((input) => input.type != "file")
        .forEach((input) => {
          body[input.id] =
            typeof input.model == "string" ? input.model.trim() : input.model;
        });

      const fileInput = this.inputs.find((input) => input.type == "file");

      if (!fileInput.model) {
        body[fileInput.id] = null;
      }

      const site = await this.postSite(body);

      if (fileInput.model && fileInput.model[0] instanceof File) {
        let formData = new FormData();

        let fileModel = {
          ref: "site",
          refId: site.id,
          field: "header_image",
          files: fileInput.model,
        };

        Object.keys(fileModel).forEach((key) => {
          if (key != "files") {
            formData.append(key, fileModel[key]);
          } else {
            fileModel[key].forEach((file) => {
              formData.append("files", file);
            });
          }
        });

        const uploadImage = await this.API.postForm("upload", formData);

        if (uploadImage.status == 200) {
          site["header_image"] = uploadImage.data[0];
        }
      }

      this.$parent.$emit("toggleSpinner", false);

      this.$parent.result.data = site;

      this.$parent.close();
    },
    postSite(body) {
      return new Promise((resolve, reject) => {
        let url = "sites";
        let method = "post";

        if (this.site) {
          url += `/${this.site._id}`;
          method = "put";
        }

        this.API[method](url, body)
          .then((retVal) => {
            return resolve(retVal.data);
          })
          .catch(() => {
            this.$parent.$emit("toggleSpinner");

            return reject();
          });
      });
    },
    inputChanged(e) {
      let input = this.inputs.find((i) => i.id == e.id);

      input.model = e.model;
    },
  },
  mounted() {
    if (this.site) {
      this.inputs = this.inputs.map((input) => {
        if (this.site[input.id]) {
          if (input.type != "file") {
            if (typeof this.site[input.id] == "object") {
              input.model = this.site[input.id]._id;
            } else {
              input.model = this.site[input.id];
            }
          } else {
            if (this.site[input.id] && this.site[input.id].url) {
              input.model = this.site[input.id];
            }
          }
        }

        return input;
      });
    }
  },
};
</script>