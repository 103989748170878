<template>
  <div id="imageViewer">
    <modal
      id="iframeViewerModal"
      :title="title || 'Document Viewer'"
      v-if="show"
      :isFull="true"
      @close="close"
    >
      <iframe :src="url" class="w-100 h-100" />
    </modal>
  </div>
</template>

<script>
import Modal from "@/components/Modal";

export default {
  name: "iframe-viewer-component",
  props: ["show", "url", "title"],
  components: {
    Modal,
  },
  methods: {
    close(e) {
      this.$emit("close", e);
    },
  },
};
</script>