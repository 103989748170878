<template>
  <div class="row mx-0 telco-cards" v-if="sharers.length > 0">
    <div
      v-for="sharer in sharers"
      :key="sharer._id"
      class="telco-item col-6 col-lg-3 d-flex flex-column justify-content-center align-items-center p-3"
    >
      <div class="px-3 pb-3">
        <img
          v-if="Helper.formatMediaUrl(sharer.telco && sharer.telco.logo)"
          :src="Helper.formatMediaUrl(sharer.telco && sharer.telco.logo)"
          class="w-100 d-block mx-auto"
        />
        <img
          v-else
          src="@/assets/images/no-image.png"
          class="w-100 d-block mx-auto rounded shadow-sm"
        />
      </div>
      <div class="w-100 telco-description">
        <div class="telco-description-item">
          <div class="mr-3 font-weight-bold">
            {{ noOfAntennas(sharer) }}
          </div>
          <div class="font-weight-light">Apparatus</div>
        </div>
        <div class="telco-description-item">
          <div class="mr-3 font-weight-bold">
            {{ noOfRRUs(sharer) }}
          </div>
          <div class="font-weight-light">RRU</div>
        </div>
        <div class="telco-description-item">
          <div class="mr-3 font-weight-bold">
            <i
              class="fas fa-check-circle text-success"
              v-if="sharer.power_utility_user"
            ></i>
            <i class="fas fa-times-circle text-muted" v-else></i>
          </div>
          <div class="font-weight-light">Power Utility User</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "sharer-breakdown-component",
  props: ["sharers", "apparatusTypes"],
  computed: {
    noOfAntennas() {
      return (sharer) => {
        if (sharer && sharer.apparatuses) {
          return sharer.apparatuses.filter(
            (a) =>
              a.apparatustype &&
              this.apparatusTypeId("Antenna", a.apparatustype)
          ).length;
        } else {
          return 0;
        }
      };
    },
    noOfRRUs() {
      return (sharer) => {
        if (sharer && sharer.apparatuses) {
          return sharer.apparatuses.filter(
            (a) =>
              a.apparatustype && this.apparatusTypeId("RRU", a.apparatustype)
          ).length;
        } else {
          return 0;
        }
      };
    },
    apparatusTypeId() {
      return (type, data) => {
        if (this.apparatusTypes && this.apparatusTypes.length > 0) {
          const apparatusType = this.apparatusTypes.find(
            (apparatus) => apparatus.name == type
          );

          return apparatusType
            ? typeof data == "object"
              ? data._id == apparatusType._id
              : data == apparatusType._id
            : null;
        } else {
          return null;
        }
      };
    },
  },
};
</script>