<template>
  <div id="view-site-overview" class="py-3">
    <div class="row mx-0 layout-with-sidebar">
      <div class="col-12 sidebar">
        <site-general-info
          :site="site"
          :options="options"
          @siteUpdated="siteUpdated"
        />
        <site-land-info
          :site="site"
          :options="options"
          @siteUpdated="siteUpdated"
        />
        <site-owner-info
          :site="site"
          :options="options"
          class="mb-0"
          @siteUpdated="siteUpdated"
        />
      </div>
      <div class="col content">
        <div class="content-item">
          <p class="text-muted font-weight-medium mr-3 px-3 pb-4">
            <i class="fas fa-list mr-2"></i>
            Summary
          </p>
          <sharer-summary
            :sharers="sharers"
            :apparatusTypes="options.apparatustype"
          />
          <p
            class="text-muted font-weight-medium mr-3 mb-0 px-3 pb-4 pt-5"
            v-if="sharers.length > 0"
          >
            <i class="fas fa-list mr-2"></i>
            Sharers
          </p>
          <sharer-breakdown
            :sharers="sharers"
            :apparatusTypes="options.apparatustype"
            class="mx-n3"
          />
          <div
            class="d-flex justify-content-between align-items-center mr-3 mb-0 pb-2 pl-3 pt-5"
          >
            <p class="text-muted font-weight-medium mb-0">
              <i class="fas fa-exclamation-triangle mr-2"></i>
              Issues Found on Site Visits
            </p>
            <button
              class="btn btn-sm btn-primary rounded-pill px-3 font-weight-medium"
              @click="viewReport(true)"
            >
              <i class="far fa-eye mr-2"></i>
              View latest report
            </button>
          </div>
          <div class="w-100">
            <img src="@/assets/images/site-overview.jpg" class="w-100 h-auto" />
          </div>
        </div>
      </div>
    </div>
    <iframe-viewer
      :show="showModal.latestReport"
      title="Maintenance Report"
      url="./report/maintenance-report_sample.pdf"
      @close="viewReport"
    />
  </div>
</template>

<script>
import SiteGeneralInfo from "@/components/SiteGeneralInfo";
import SiteLandInfo from "@/components/SiteLandInfo";
import SiteOwnerInfo from "@/components/SiteOwnerInfo";
import SharerSummary from "@/components/SharerSummary";
import SharerBreakdown from "@/components/SharerBreakdown";
import IframeViewer from "@/components/IframeViewer";

export default {
  name: "site-overview-view",
  props: ["site"],
  components: {
    SiteGeneralInfo,
    SiteLandInfo,
    SiteOwnerInfo,
    SharerSummary,
    SharerBreakdown,
    IframeViewer,
  },
  data() {
    return {
      sharers: [],
      options: {
        state: [],
        structuretype: [],
        cme_contractor: [],
        maintenance_contractor: [],
        structure_supplier: [],
        landowner: [],
        paymentmode: [],
        telco: [],
        apparatustype: [],
      },
      showModal: {
        latestReport: false,
      },
    };
  },
  watch: {
    $route: {
      async handler() {
        if (this.$route.name == "site.overview") {
          await this.getSharers();
          await this.getStates();
          await this.getStructureTypes();
          await this.getContractors();
          await this.getSuppliers();
          await this.getLandowners();
          await this.getPaymentModes();
          await this.getApparatusTypes();
        }
      },
      deep: true,
      immediate: true,
    },
  },
  methods: {
    viewReport(e) {
      if (typeof e == "boolean") {
        this.showModal.latestReport = e;
      } else if (typeof e == "object") {
        this.showModal.latestReport = e.show;
      }
    },
    toggleSpinner(e) {
      this.$emit("toggleSpinner", e);
    },
    siteUpdated(result) {
      if (result && result.type == "toggleSpinner") {
        this.$emit("toggleSpinner", result.data);
      } else if (result) {
        this.$emit("updated", "site", result);
      }
    },
    async getStates() {
      this.$emit("toggleSpinner", true);

      return this.API.get("states?_sort=name:ASC")
        .then((retVal) => {
          this.options.state = retVal.data;

          this.$emit("toggleSpinner", false);

          return;
        })
        .catch(() => {
          this.$emit("toggleSpinner", false);

          return;
        });
    },
    async getStructureTypes() {
      this.$emit("toggleSpinner", true);

      return this.API.get("structuretypes?_sort=name:ASC")
        .then((retVal) => {
          this.options.structuretype = retVal.data;

          this.$emit("toggleSpinner", false);

          return;
        })
        .catch(() => {
          this.$emit("toggleSpinner", false);

          return;
        });
    },
    async getContractors() {
      this.$emit("toggleSpinner", true);

      return this.API.get("contractors?_sort=name:ASC")
        .then((retVal) => {
          this.options.cme_contractor = retVal.data;
          this.options.maintenance_contractor = retVal.data;

          this.$emit("toggleSpinner", false);

          return;
        })
        .catch(() => {
          this.$emit("toggleSpinner", false);

          return;
        });
    },
    async getSuppliers() {
      this.$emit("toggleSpinner", true);

      return this.API.get("suppliers?_sort=name:ASC")
        .then((retVal) => {
          this.options.structure_supplier = retVal.data;

          this.$emit("toggleSpinner", false);

          return;
        })
        .catch(() => {
          this.$emit("toggleSpinner", false);

          return;
        });
    },
    async getLandowners() {
      this.$emit("toggleSpinner", true);

      return this.API.get("landowners?_sort=name:ASC")
        .then((retVal) => {
          this.options.landowner = retVal.data;

          this.$emit("toggleSpinner", false);

          return;
        })
        .catch(() => {
          this.$emit("toggleSpinner", false);

          return;
        });
    },
    async getPaymentModes() {
      this.$emit("toggleSpinner", true);

      return this.API.get("paymentmodes?_sort=name:ASC")
        .then((retVal) => {
          this.options.paymentmode = retVal.data;

          this.$emit("toggleSpinner", false);

          return;
        })
        .catch(() => {
          this.$emit("toggleSpinner", false);

          return;
        });
    },
    async getSharers() {
      this.$emit("toggleSpinner", true);

      return this.API.get(`sharerinformations?site=${this.$route.params.id}`)
        .then((retVal) => {
          this.sharers = retVal.data;

          this.sharers.sort((a, b) => {
            return a.telco && b.telco && b.telco.name < a.telco.name;
          });

          this.$emit("toggleSpinner", false);

          return;
        })
        .catch(() => {
          this.$emit("toggleSpinner", false);

          return;
        });
    },
    async getApparatusTypes() {
      this.$emit("toggleSpinner", true);

      return this.API.get(`apparatustypes?_sort=name:ASC`)
        .then((retVal) => {
          this.options.apparatustype = retVal.data;

          this.$emit("toggleSpinner", false);

          return;
        })
        .catch(() => {
          this.$emit("toggleSpinner", false);

          return;
        });
    },
  },
};
</script>