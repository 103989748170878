<template>
  <div class="custom-card">
    <div class="custom-card-header">
      <div>
        <i class="far fa-file-alt mr-2"></i>
        General Info
      </div>
      <button class="btn p-0 text-primary" @click="showModal = true">
        <i class="far fa-edit"></i>
      </button>
    </div>
    <div class="row mx-0 p-4">
      <div class="col-4 custom-card-title">Site ID</div>
      <div class="col-8">{{ site && site.site_id }}</div>
      <div class="col-4 custom-card-title">Site Name</div>
      <div class="col-8">{{ site && site.name }}</div>
      <div class="col-4 custom-card-title">Address</div>
      <div class="col-8">
        {{ site && Helper.formatAddress(site.address, site.city) }}
      </div>
      <div class="col-4 custom-card-title">State</div>
      <div class="col-8">{{ site && site.state ? site.state.name : "" }}</div>
      <div class="col-4 custom-card-title">Coordinate</div>
      <div class="col-8">
        {{ site && Helper.formatCoordinate([site.lat, site.lng]) }}
      </div>
      <div class="col-4 custom-card-title">Structure Type</div>
      <div class="col-8">
        {{ site && site.structuretype ? site.structuretype.name : "" }}
      </div>
      <div class="col-4 custom-card-title">Genset</div>
      <div class="col-8">{{ site && site.genset ? "Yes" : "No" }}</div>
      <div class="col-4 custom-card-title">TNB Meter No.</div>
      <div class="col-8">{{ site && site.tnb_meter_no }}</div>
      <div class="col-4 custom-card-title">TNB Account No.</div>
      <div class="col-8">{{ site && site.tnb_account_no }}</div>
    </div>
    <modal
      id="editSiteGeneralInfoModal"
      v-if="showModal"
      title="Edit site"
      @toggleSpinner="toggleSpinner"
      @close="closeModal"
    >
      <site-info-form :options="options" :site="site" />
    </modal>
  </div>
</template>

<script>
import Modal from "@/components/Modal";
import SiteInfoForm from "@/components/forms/SiteInfo";

export default {
  name: "site-general-info-component",
  props: ["site", "options"],
  components: {
    Modal,
    SiteInfoForm,
  },
  data() {
    return {
      showModal: false,
    };
  },
  methods: {
    toggleSpinner(e) {
      this.$parent.$emit("toggleSpinner", e);
    },
    closeModal(result) {
      if (typeof result == "object") {
        this.showModal = result.show;

        if (result.data) {
          this.$emit("siteUpdated", result.data);
        }
      } else if (typeof result == "boolean") {
        this.showModal = result;
      }
    },
  },
};
</script>