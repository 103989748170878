<template>
  <div class="custom-card">
    <div class="custom-card-header">
      <div>
        <i class="far fa-file-alt mr-2"></i>
        Landowner Info
      </div>
      <button class="btn p-0 text-primary" @click="showModal = true">
        <i class="far fa-edit"></i>
      </button>
    </div>
    <div class="row mx-0 p-4">
      <div class="col-4 custom-card-title">Name</div>
      <div class="col-8">
        {{ landownerInformation && landownerInformation.name }}
      </div>
      <div class="col-4 custom-card-title">Category</div>
      <div class="col-8">
        {{ landownerInformation && landownerInformation.land_category }}
      </div>
      <div class="col-4 custom-card-title">Address</div>
      <div class="col-8">
        {{ landownerInformation && landownerInformation.address }}
      </div>
      <div class="col-4 custom-card-title">Phone Number</div>
      <div class="col-8">
        {{ landownerInformation && landownerInformation.phone_no }}
      </div>
      <div class="col-4 custom-card-title">Account No.</div>
      <div class="col-8">
        {{ landownerInformation && landownerInformation.bank_account_no }}
      </div>
      <div class="col-4 custom-card-title">Payment Mode</div>
      <div class="col-8">
        {{
          siteRentalInformation &&
          siteRentalInformation.paymentmode &&
          siteRentalInformation.paymentmode.name
        }}
      </div>
      <div class="col-4 custom-card-title">Rental per Month</div>
      <div class="col-8">
        {{
          siteRentalInformation && siteRentalInformation.amount
            ? Helper.formatMoney(siteRentalInformation.amount)
            : ""
        }}
      </div>
      <div class="col-4 custom-card-title">Rental Deposit</div>
      <div class="col-8">
        {{
          siteRentalInformation && siteRentalInformation.deposit
            ? Helper.formatMoney(siteRentalInformation.deposit)
            : ""
        }}
      </div>
      <div class="col-4 custom-card-title">Term of Tenancy</div>
      <div class="col-8">
        {{ siteRentalInformation && siteRentalInformation.term }}
      </div>
      <div class="col-4 custom-card-title">Renewal Option</div>
      <div class="col-8">
        {{
          siteRentalInformation && siteRentalInformation.renewal_option != null
            ? siteRentalInformation.renewal_option
              ? "Yes"
              : "No"
            : ""
        }}
      </div>
      <div class="col-4 custom-card-title">Comm. Date</div>
      <div class="col-8">
        {{
          siteRentalInformation &&
          Helper.formatDate(siteRentalInformation.commencement_date)
        }}
      </div>
      <div class="col-4 custom-card-title">Expiry Date</div>
      <div class="col-8">
        {{
          siteRentalInformation &&
          Helper.formatDate(siteRentalInformation.expiry_date)
        }}
      </div>
    </div>
    <modal
      id="editSiteOwnerInfoModal"
      v-if="showModal"
      title="Edit owner info"
      @toggleSpinner="toggleSpinner"
      @close="closeModal"
    >
      <owner-info-form
        :site="site"
        :options="options"
        :siterentalinformation="siteRentalInformation"
      />
    </modal>
  </div>
</template>

<script>
import Modal from "@/components/Modal";
import OwnerInfoForm from "@/components/forms/OwnerInfo";

export default {
  name: "site-owner-component",
  props: ["options", "site"],
  components: {
    Modal,
    OwnerInfoForm,
  },
  data() {
    return {
      showModal: false,
      siteRentalInformation: null,
      landownerInformation: null,
    };
  },
  watch: {
    site: {
      async handler() {
        if (
          this.site &&
          this.site.siterentalinformation &&
          this.site.siterentalinformation._id
        ) {
          await this.getSiteRentalInformation();
        } else {
          this.siteRentalInformation = null;
          this.landownerInformation = null;
        }
      },
      deep: true,
      immediate: true,
    },
  },
  methods: {
    toggleSpinner(e) {
      this.$parent.$emit("toggleSpinner", e);
    },
    closeModal(result) {
      if (typeof result == "object") {
        this.showModal = result.show;

        if (result.data) {
          this.siteRentalInformation = result.data;

          this.landownerInformation = result.data.landowner;
        }
      } else if (typeof result == "boolean") {
        this.showModal = result;
      }
    },
    async getSiteRentalInformation() {
      this.toggleSpinner(true);

      this.API.get(
        `siterentalinformations/${this.site.siterentalinformation._id}`
      )
        .then((retVal) => {
          this.siteRentalInformation = retVal.data;

          this.landownerInformation = retVal.data
            ? retVal.data.landowner
            : null;

          this.toggleSpinner(false);

          return;
        })
        .catch(() => {
          this.toggleSpinner(false);

          return;
        });
    },
  },
};
</script>