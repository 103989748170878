<template>
  <div class="custom-card">
    <div class="custom-card-header">
      <div>
        <i class="far fa-file-alt mr-2"></i>
        Land Info
      </div>
      <button class="btn p-0 text-primary" @click="showModal = true">
        <i class="far fa-edit"></i>
      </button>
    </div>
    <div class="row mx-0 p-4">
      <div class="col-4 custom-card-title">CME Contractor</div>
      <div class="col-8">
        {{ site && site.cme_contractor && site.cme_contractor.name }}
      </div>
      <div class="col-4 custom-card-title">Structure Supplier</div>
      <div class="col-8">
        {{ site && site.structure_supplier && site.structure_supplier.name }}
      </div>
      <div class="col-4 custom-card-title">Maintenance Contractor</div>
      <div class="col-8">
        {{
          site &&
          site.maintenance_contractor &&
          site.maintenance_contractor.name
        }}
      </div>
      <div class="col-4 custom-card-title">Maintenance Sequence</div>
      <div class="col-8">
        {{
          site && site.maintenance_sequence
            ? `${site.maintenance_sequence} month`
            : ""
        }}
      </div>
      <div class="col-4 custom-card-title">Handover Date</div>
      <div class="col-8">
        {{ site && Helper.formatDate(site.handover_date) }}
      </div>
    </div>
    <modal
      id="editSiteLandInfoModal"
      v-if="showModal"
      title="Edit land info"
      @toggleSpinner="toggleSpinner"
      @close="closeModal"
    >
      <land-info-form :options="options" :site="site" />
    </modal>
  </div>
</template>

<script>
import Modal from "@/components/Modal";
import LandInfoForm from "@/components/forms/LandInfo";

export default {
  name: "site-land-info-component",
  props: ["site", "options"],
  components: {
    Modal,
    LandInfoForm,
  },
  data() {
    return {
      showModal: false,
    };
  },
  methods: {
    toggleSpinner(e) {
      this.$parent.$emit("toggleSpinner", e);
    },
    closeModal(result) {
      if (typeof result == "object") {
        this.showModal = result.show;

        if (result.data) {
          this.$emit("siteUpdated", result.data);
        }
      } else if (typeof result == "boolean") {
        this.showModal = result;
      }
    },
  },
};
</script>