<template>
  <div class="form-group form-input-switch">
    <label class="font-weight-medium">
      {{ input.label }}
    </label>
    <div class="row mx-0 bg-light">
      <div class="col p-0">
        <button
          type="button"
          class="btn btn-block"
          :class="model ? 'btn-light' : 'btn-primary'"
          @click="toggleModel()"
        >
          No
        </button>
      </div>
      <div class="col p-0">
        <button
          type="button"
          class="btn btn-block"
          :class="model ? 'btn-primary' : 'btn-light'"
          @click="toggleModel()"
        >
          Yes
        </button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "switch-input-component",
  props: ["input"],
  data() {
    return {
      model: null,
    };
  },
  watch: {
    "input.model": {
      handler() {
        this.model = this.input.model ? true : false;
      },
      deep: true,
      immediate: true,
    },
    model() {
      this.$emit("change", { id: this.input.id, model: this.model });
    },
  },
  methods: {
    toggleModel() {
      this.model = !this.model;
    },
  },
};
</script>