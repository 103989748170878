<template>
  <div class="row mx-0">
    <div
      class="col-6 col-lg-3 d-flex flex-column justify-content-center align-items-center p-3"
    >
      <h1>{{ (sharers && sharers.length) || 0 }}</h1>
      <p class="lead">
        <span class="badge badge-light shadow-sm badge-pill py-1 px-3">
          Total Sharers
        </span>
      </p>
    </div>
    <div
      class="col-6 col-lg-3 d-flex flex-column justify-content-center align-items-center p-3"
    >
      <h1>{{ (powerUtilityUsers && powerUtilityUsers.length) || 0 }}</h1>
      <p class="lead">
        <span class="badge badge-light shadow-sm badge-pill py-1 px-3">
          Power Utility Users
        </span>
      </p>
    </div>
    <div
      class="col-6 col-lg-3 d-flex flex-column justify-content-center align-items-center p-3"
    >
      <h1>{{ totalApparatus }}</h1>
      <p class="lead">
        <span class="badge badge-light shadow-sm badge-pill py-1 px-3">
          Total Apparatus
        </span>
      </p>
    </div>
    <div
      class="col-6 col-lg-3 d-flex flex-column justify-content-center align-items-center p-3"
    >
      <h1>{{ totalRRU }}</h1>
      <p class="lead">
        <span class="badge badge-light shadow-sm badge-pill py-1 px-3">
          Total RRU
        </span>
      </p>
    </div>
  </div>
</template>

<script>
export default {
  name: "sharer-summary-component",
  props: ["sharers", "apparatusTypes"],
  computed: {
    powerUtilityUsers() {
      return (
        this.sharers &&
        this.sharers.filter((sharer) => sharer.power_utility_user)
      );
    },
    totalApparatus() {
      let result = 0;

      this.sharers.forEach((sharer) => {
        result += sharer.apparatuses
          ? sharer.apparatuses.filter(
              (a) =>
                a.apparatustype &&
                a.apparatustype == this.apparatusTypeId("Antenna")
            ).length
          : 0;
      });

      return result;
    },
    totalRRU() {
      let result = 0;

      this.sharers.forEach((sharer) => {
        result += sharer.apparatuses
          ? sharer.apparatuses.filter(
              (a) =>
                a.apparatustype &&
                a.apparatustype == this.apparatusTypeId("RRU")
            ).length
          : 0;
      });

      return result;
    },
    apparatusTypeId() {
      return (type) => {
        if (this.apparatusTypes && this.apparatusTypes.length > 0) {
          const apparatusType = this.apparatusTypes.find(
            (apparatus) => apparatus.name == type
          );

          return apparatusType ? apparatusType._id : null;
        } else {
          return null;
        }
      };
    },
  },
};
</script>