<template>
  <div class="form">
    <div class="row pb-4">
      <template v-for="input in inputs">
        <component
          :is="`${input.type}-input`"
          :key="input.id"
          :input="input"
          :options="options && options[input.id]"
          :class="`${
            typeof input.col != 'undefined'
              ? input.col === 0
                ? 'col'
                : 'col-'.concat(input.col)
              : 'col-12'
          }`"
          @change="inputChanged"
        />
      </template>
    </div>
    <div
      class="pt-3 border-top d-flex justify-content-between align-items-center"
    >
      <button class="btn btn-light px-4" @click="close()">Cancel</button>
      <button class="btn btn-success px-4" @click="save()">
        <i class="fas fa-save mr-2"></i>
        Save
      </button>
    </div>
  </div>
</template>

<script>
import TextInput from "@/components/inputs/Text";
import TextareaInput from "@/components/inputs/Textarea";
import SelectInput from "@/components/inputs/Select";
import SwitchInput from "@/components/inputs/Switch";
import CalendarInput from "@/components/inputs/Calendar";

export default {
  name: "land-info-form-component",
  props: ["options", "site"],
  components: {
    TextInput,
    TextareaInput,
    SelectInput,
    SwitchInput,
    CalendarInput,
  },
  data() {
    return {
      inputs: [
        {
          type: "select",
          label: "CME Contractor",
          id: "cme_contractor",
          model: null,
        },
        {
          type: "select",
          label: "Structure Supplier",
          id: "structure_supplier",
          model: null,
        },
        {
          type: "select",
          label: "Maintenance Contractor",
          id: "maintenance_contractor",
          model: null,
        },
        {
          type: "text",
          label: "Maintenance Sequence (months)",
          id: "maintenance_sequence",
          model: null,
          col: 6,
        },
        {
          type: "calendar",
          label: "Handover Date",
          id: "handover_date",
          model: null,
          col: 6,
        },
      ],
    };
  },
  methods: {
    close() {
      this.$parent.close();
    },
    async save() {
      this.$parent.$emit("toggleSpinner", true);

      let body = {};

      this.inputs.forEach((input) => {
        body[input.id] =
          typeof input.model == "string" ? input.model.trim() : input.model;
      });

      const site = await this.postSite(body);

      this.$parent.$emit("toggleSpinner", false);

      this.$parent.result.data = site;

      this.$parent.close();
    },
    postSite(body) {
      return new Promise((resolve, reject) => {
        let url = "sites";
        let method = "post";

        if (this.site) {
          url += `/${this.site._id}`;
          method = "put";
        }

        this.API[method](url, body)
          .then((retVal) => {
            return resolve(retVal.data);
          })
          .catch(() => {
            this.$parent.$emit("toggleSpinner");

            return reject();
          });
      });
    },
    inputChanged(e) {
      let input = this.inputs.find((i) => i.id == e.id);

      input.model = e.model;
    },
  },
  mounted() {
    if (this.site) {
      this.inputs = this.inputs.map((input) => {
        if (this.site[input.id]) {
          if (typeof this.site[input.id] == "object") {
            input.model = this.site[input.id]._id;
          } else {
            input.model = this.site[input.id];
          }
        }

        return input;
      });
    }
  },
};
</script>